import { DateRangePicker } from "@asantech/common/react/DateRangePicker";
import { Schedule } from "components/Schedule";
import { min } from "date-fns";
import { useCallback } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import styled from "styled-components";
import {
  CornerPos,
  PeriodByWeekDay,
} from "../../../../../backend/src/devices/types";
import {
  DetectionGroup,
  validDetectionGroups,
} from "../../../../../backend/src/events/types";
import { ReactComponent as PentagonIcon } from "../../../assets/svgs/pentagon-icon.svg";
import { useDelayedValue } from "../../../common/hooks/use-delayed-value";
import { FieldRow } from "../../../components";
import { ToggleButton } from "../ToggleButton";

export type CameraSettingsForm = {
  areaToScan: CornerPos[];
  detectionGroups: DetectionGroup[];
  activitySchedule: PeriodByWeekDay[];
  activeTo?: Date | null;
};

interface Props {
  form: UseFormReturn<CameraSettingsForm>;
  toggleDrawing: () => void;
  drawingActive: boolean;
  isSavePending: boolean;
}

export const CameraSettingsSideContent = (props: Props) => {
  const { toggleDrawing, drawingActive, form, isSavePending } = props;
  const { control, setValue } = form;

  const delayedIsSaving = useDelayedValue(isSavePending);

  const toggleDetectionGroup = useCallback(
    (name: string, value: string[]) => {
      const isToggled = value.includes(name);
      const notName = (n: string) => n !== name;
      const newValue = isToggled ? value.filter(notName) : [...value, name];

      if (!newValue.length) {
        const otherDetections = validDetectionGroups.filter(notName);

        if (otherDetections[0]) newValue.push(otherDetections[0]);
      }

      setValue("detectionGroups", newValue);
    },
    [setValue]
  );

  return (
    <SideContent>
      <SideTopBar>
        <p>Define an area to scan.</p>
        <p>{isSavePending ? "Saving ..." : delayedIsSaving ? "Saved" : ""}</p>
      </SideTopBar>
      <ToggleButton onClick={toggleDrawing} active={drawingActive}>
        <PentagonIcon />
      </ToggleButton>

      {drawingActive && (
        <SettingsWrapper>
          <FieldRow
            label="Alert classes"
            tooltip="Detection classes that will trigger an alert"
          >
            <Controller
              name="detectionGroups"
              control={control}
              render={({ field }) => (
                <DetectionGroups>
                  {validDetectionGroups.map((value) => (
                    <ToggleButton
                      key={value}
                      onClick={() => toggleDetectionGroup(value, field.value)}
                      active={field.value.includes(value)}
                    >
                      {value}
                    </ToggleButton>
                  ))}
                </DetectionGroups>
              )}
            />
          </FieldRow>
          <div>
            <LabelText>Active until</LabelText>
            <Controller
              name="activeTo"
              control={control}
              render={({ field }) => (
                <DateRangePickerStyled
                  timeSelection
                  singleDateSelection
                  onChange={(range) => field.onChange(range[0])}
                  value={[field.value || null, null]}
                  placeholder="No end day"
                  minDate={
                    field.value ? min([new Date(), field.value]) : new Date()
                  }
                />
              )}
            ></Controller>
          </div>
          <div>
            <LabelText>Activity schedule</LabelText>
            <Controller
              name="activitySchedule"
              control={control}
              render={({ field }) => (
                <Schedule
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                ></Schedule>
              )}
            />
          </div>
        </SettingsWrapper>
      )}
    </SideContent>
  );
};

const SideContent = styled.div`
  padding: 0 16px;
`;

const SideTopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const SettingsWrapper = styled.div`
  padding-top: 20px;
`;

const DetectionGroups = styled.div`
  margin-bottom: 32px;

  & > * {
    margin-right: 10px;
  }
`;

export const LabelText = styled.div`
  margin: 20px 0 12px 0;
  font-size: 12px;
`;

const DateRangePickerStyled = styled(DateRangePicker)`
  width: 100%;

  & > button {
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
